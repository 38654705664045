@if (error()) {
  <app-info-block
    variety="error"
    [description]="error() || 'Неизвестная ошибка'"
  />
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && favouritesUsers().length < 1"
  emptyDescription="Список избранных пользователей пуст"
>
  @for (
    announcement of favouritesUsers();
    let index = $index;
    track announcement.id
  ) {
    <!-- <app-my-announcements-card
      variety="draft"
      [announcement]="announcement"
      [isLastCard]="myAnnouncements().length - 1 === index"
    /> -->
  }

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
