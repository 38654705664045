import { Component, DestroyRef, afterNextRender, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  throttleTime,
} from 'rxjs';

import { DataWrapperComponent } from '@components/common/data-wrapper/data-wrapper.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';

import { FavouritesService } from '../../core/favourites.service';

@Component({
  selector: 'app-favourites-announcements',
  standalone: true,
  imports: [DataWrapperComponent, LoaderGlobalComponent, InfoBlockComponent],
  templateUrl: './favourites-announcements.component.html',
  styleUrl: './favourites-announcements.component.scss',
})
export class FavouritesAnnouncementsComponent {
  favouritesAnnouncements = this.favouritesService.favouritesAnnouncementsList;

  isLoading = this.favouritesService.announcementsLoader;
  isInfiniteLoading = this.favouritesService.announcementsInfiniteLoader;
  error = this.favouritesService.announcementsError;

  destroyRef = inject(DestroyRef);

  constructor(private favouritesService: FavouritesService) {
    afterNextRender(() => {
      this.favouritesService.loadFavouritesAnnouncements({});
      this.favouritesService.loadFavouritesCounts();

      // Высота footer или мобильного footer + половина высоты карточки объявления
      const footerHeight =
        document.getElementById('footer')?.clientHeight || 70;

      const pageByScroll$ = fromEvent(window, 'scroll', { passive: true }).pipe(
        map(() => window.scrollY),
        filter(
          (scrollY) =>
            scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footerHeight -
              210,
        ),
        throttleTime(200),
        distinctUntilChanged(),
      );

      pageByScroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        if (!this.isLoading() && !this.isInfiniteLoading())
          this.favouritesService.loadFavouritesAnnouncements({
            isInfinite: true,
          });
      });
    });
  }
}
