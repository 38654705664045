import { TMenuItemsString } from '@models/commonTypes';
import {
  ROUTE_FAVOURITES,
  ROUTE_FAVOURITES_USERS,
  ROUTE_PROFILE,
} from '@settings/routes';

export const favouritesMenuList: TMenuItemsString = [
  {
    id: 'favourites-announcements',
    link: `/${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`,
    name: 'Объявления',
  },
  {
    id: 'favourites-users',
    link: ROUTE_FAVOURITES_USERS,
    name: 'Пользователи',
  },
];
