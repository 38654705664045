import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { INITIAL_PER_PAGE } from '@settings/constants';
import { getPaginationData } from '@utils/getPaginationHeaders';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TAnnouncement } from '@models/announcementTypes';
import { TFavouritesCounts, TFavouritesUsers } from './favouritesTypes';
import { TBooleanResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FavouritesService {
  #favouritesEntityCounts = signal<number[]>([]);
  favouritesEntityCounts = computed(this.#favouritesEntityCounts);

  #favouritesAnnouncementsList = signal<TAnnouncement[]>([]);
  favouritesAnnouncementsList = computed(this.#favouritesAnnouncementsList);

  #announcementsCurrentPage = signal<number>(0);
  announcementsCurrentPage = computed(this.#announcementsCurrentPage);

  #announcementsTotalPage = signal<number>(0);
  announcementsTotalPage = computed(this.#announcementsTotalPage);

  #announcementsPerPage = signal<number>(0);
  announcementsPerPage = computed(this.#announcementsPerPage);

  #announcementsTotalCount = signal<number>(0);
  announcementsTotalCount = computed(this.#announcementsTotalCount);

  #announcementsLoader = signal<boolean>(true);
  announcementsLoader = computed(this.#announcementsLoader);

  #announcementsInfiniteLoader = signal<boolean>(false);
  announcementsInfiniteLoader = computed(this.#announcementsInfiniteLoader);

  #announcementsError = signal<string | null>(null);
  announcementsError = computed(this.#announcementsError);

  #favouritesUsersList = signal<TFavouritesUsers>([]);
  favouritesUsersList = computed(this.#favouritesUsersList);

  #usersCurrentPage = signal<number>(0);
  usersCurrentPage = computed(this.#usersCurrentPage);

  #usersTotalPage = signal<number>(0);
  usersTotalPage = computed(this.#usersTotalPage);

  #usersPerPage = signal<number>(0);
  usersPerPage = computed(this.#usersPerPage);

  #usersTotalCount = signal<number>(0);
  usersTotalCount = computed(this.#usersTotalCount);

  #usersLoader = signal<boolean>(true);
  usersLoader = computed(this.#usersLoader);

  #usersInfiniteLoader = signal<boolean>(false);
  usersInfiniteLoader = computed(this.#usersInfiniteLoader);

  #usersError = signal<string | null>(null);
  usersError = computed(this.#usersError);

  constructor(private http: HttpClient) {}

  // Список избранных объявлений
  loadFavouritesAnnouncements({ isInfinite }: { isInfinite?: boolean }): void {
    if (
      this.#announcementsCurrentPage() >= this.#announcementsTotalPage() &&
      this.#announcementsCurrentPage() !== 0
    )
      return;

    if (isInfinite) {
      this.#announcementsInfiniteLoader.set(true);
    } else {
      this.#announcementsLoader.set(true);
    }

    this.#announcementsCurrentPage.set(this.#announcementsCurrentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#announcementsCurrentPage(),
    };

    this.http
      .get<TAnnouncement[]>(
        `${environment.getApiVersionUrl('favorites/announcements')}`,
        {
          params: allParams,
          transferCache: {
            includeHeaders: [
              'X-Pagination-Current-Page',
              'X-Pagination-Page-Count',
              'X-Pagination-Per-Page',
              'X-Pagination-Total-Count',
            ],
          },
          observe: 'response',
        },
      )
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TAnnouncement[]>(response);

          this.#announcementsCurrentPage.set(responsePagination.currentPage);
          this.#announcementsTotalPage.set(responsePagination.totalPage);
          this.#announcementsPerPage.set(responsePagination.perPage);
          this.#announcementsTotalCount.set(responsePagination.totalCount);

          this.#favouritesAnnouncementsList.set([
            ...this.#favouritesAnnouncementsList(),
            ...responsePagination.data,
          ]);

          if (isInfinite) {
            this.#announcementsInfiniteLoader.set(false);
          } else {
            this.#announcementsLoader.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#announcementsError.set(error.formattedErrorMessage);

          if (isInfinite) {
            this.#announcementsInfiniteLoader.set(false);
          } else {
            this.#announcementsLoader.set(false);
          }
        },
      });
  }

  // Список избранных пользователей
  loadFavouritesUsers({ isInfinite }: { isInfinite?: boolean }): void {
    if (
      this.#usersCurrentPage() >= this.#usersTotalPage() &&
      this.#usersCurrentPage() !== 0
    )
      return;

    if (isInfinite) {
      this.#usersInfiniteLoader.set(true);
    } else {
      this.#usersLoader.set(true);
    }

    this.#usersCurrentPage.set(this.#usersCurrentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#usersCurrentPage(),
    };

    this.http
      .get<TFavouritesUsers>(
        `${environment.getApiVersionUrl('favorites/clients')}`,
        {
          params: allParams,
          transferCache: {
            includeHeaders: [
              'X-Pagination-Current-Page',
              'X-Pagination-Page-Count',
              'X-Pagination-Per-Page',
              'X-Pagination-Total-Count',
            ],
          },
          observe: 'response',
        },
      )
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TFavouritesUsers>(response);

          this.#usersCurrentPage.set(responsePagination.currentPage);
          this.#usersTotalPage.set(responsePagination.totalPage);
          this.#usersPerPage.set(responsePagination.perPage);
          this.#usersTotalCount.set(responsePagination.totalCount);

          this.#favouritesUsersList.set([
            ...this.#favouritesUsersList(),
            ...responsePagination.data,
          ]);

          if (isInfinite) {
            this.#usersInfiniteLoader.set(false);
          } else {
            this.#usersLoader.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#usersError.set(error.formattedErrorMessage);

          if (isInfinite) {
            this.#usersInfiniteLoader.set(false);
          } else {
            this.#usersLoader.set(false);
          }
        },
      });
  }

  // Список количества избранных объявлений и избранных пользователей
  loadFavouritesCounts(): void {
    this.http
      .get<TFavouritesCounts>(
        `${environment.getApiVersionUrl('favorites/counts')}`,
      )
      .subscribe({
        next: (response) => {
          this.#favouritesEntityCounts.set(Object.values(response));
        },
        error: (error: TFormattedApiError) => {
          console.error(error.formattedErrorMessage);
        },
      });
  }

  // Добавление объявления в избранное
  addingAnnouncementToFavorites(slug: string): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl(`favorites/announcements/${slug}`)}`,
      {},
    );
  }

  // Удаление объявления из избранного
  deletingAnnouncementFromFavorites(
    slug: string,
  ): Observable<TBooleanResponse> {
    return this.http.delete<TBooleanResponse>(
      `${environment.getApiVersionUrl(`favorites/announcements/${slug}`)}`,
    );
  }

  // Добавление клиента в избранное
  addingUserToFavorites(id: number): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl(`/v1/favorites/clients/${id}`)}`,
      {},
    );
  }

  // Удаление клиента из избранного
  deletingUserFromFavorites(id: number): Observable<TBooleanResponse> {
    return this.http.delete<TBooleanResponse>(
      `${environment.getApiVersionUrl(`/v1/favorites/clients/${id}`)}`,
    );
  }
}
